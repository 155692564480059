<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2016-2022 All rights reserved.
  - MyTh Ahmed Fayez
  -->

<template>
  <v-container fluid>
    <app-row class="d-print-none">
      <app-col cols="12">
        <v-toolbar>
          <v-toolbar-title>{{ getContainerTitle }}</v-toolbar-title>
        </v-toolbar>
      </app-col>
    </app-row>
    <app-row
      v-if="showUpload"
      align="center"
      class="d-print-none"
    >
      <app-text-input
        v-if="textType"
        v-model="attachmentsForm.attachment_type"
        cols="12"
        name="attachment_type"
        sm="6"
      />
      <app-attachment-types
        v-else
        v-model="attachmentsForm.attachment_type"
        cols="12"
        name="attachment_type"
        sm="6"
      />
      <app-file-input
        v-model="attachmentsForm.file"
        :accept="accept"
        cols="5"
        name="attachment"
      />
      <v-spacer />
      <v-col
        align-self="center"
        cols="auto"
      >
        <app-btn
          :disabled="!(attachmentsForm.attachment_type && attachmentsForm.file)"
          :loading="loader"
          color="success"
          @click="uploadAttachments"
        >
          {{ $t('upload') }}
        </app-btn>
      </v-col>
    </app-row>
    <app-row
      v-if="getAttachments"
      class="mt-2 d-print-none"
      dense
    >
      <template
        v-for="(attachment,aIndex) in getAttachments"
      >
        <v-col
          v-if="attachment"
          :key="aIndex"
          cols="auto"
        >
          <attachment-block
            :attachment="attachment"
            :show-destroy="showDestroy"
            :url="getUrl"
            @update-item="updateItem($event)"
          />
        </v-col>
      </template>
    </app-row>
    <v-dialog
      v-model="loader"
      no-click-animation
      persistent
      width="250"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          &nbsp;
          <v-progress-linear
            class="mb-0"
            color="white"
            indeterminate
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import AttachmentBlock from '@components/attachments/AttachmentBlock'

const defForm = {
  file: undefined,
  attachment_type: undefined
}

export default {
  name: 'AttachmentsContainer',
  components: { AttachmentBlock },
  events: {},
  props: {
    item: {
      required: !0,
      type: Object,
      default: () => undefined
    },
    attachments: {
      required: !0,
      type: Array,
      default: () => undefined
    },
    urls: {
      required: !0,
      type: Object,
      default: () => undefined
    },
    title: {
      required: !1,
      type: String,
      default: () => undefined
    },
    loading: {
      required: !1,
      type: Boolean,
      default: () => undefined
    },
    form: {
      required: !1,
      type: Object,
      default: () => ({ ...defForm })
    },
    errors: {
      required: !1,
      type: Object,
      default: () => ({ ...defForm })
    },
    accept: {
      required: !1,
      type: String,
      default: () => undefined
    },
    textType: {
      required: !1,
      type: Boolean,
      default: () => !1
    },
    showUpload: {
      required: !1,
      type: Boolean,
      default: () => !0
    },
    showDestroy: {
      required: !1,
      type: Boolean,
      default: () => !0
    }
  },
  data () {
    return {
      loader: !1,
      files: [],
      attachmentsForm: {},
      formErrors: {}
    }
  },
  computed: {
    getContainerTitle () {
      return this.title === undefined ? this.parseAttribute('attachments') : this.title
    },
    getAttachments () {
      return this.attachments
    },
    getUrl () {
      return this.urls
    }
  },
  watch: {
    loader (v) {
      this.$emit('update:loading', v)
    },
    attachmentsForm (v) {
      this.$emit('update:form', v)
    },
    files (v) {
      this.$emit('update:attachments', v)
    },
    formErrors (v) {
      this.$emit('update:errors', v)
    }
  },
  methods: {
    updateItem (v) {
      this.files = v
      // this.$emit('update:item', v)
    },
    uploadAttachments () {
      const id = this.item?.id
      const { attachmentsForm: { file, attachment_type } } = this
      if (!(file && attachment_type && id)) {
        return
      }

      this.confirmMessage(() => {
        this.loader = !0
        this.formErrors = {}
        const formData = new FormData()
        // console.log(attachment_type)
        const typeName = attachment_type?.name || attachment_type
        formData.append('attachment', file, file.name)
        formData.append('attachment_type', typeName)
        this.getUrl.uploadAttachments(id, formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(r => {
          const { _success, _message, _data } = r
          // _message && this.alertSuccess(_message)
          _success && (this.attachmentsForm = {
            file: undefined,
            attachment_type: undefined
          })
          // _data && (this.updateItem(_data))
          _data && (this.files = _data.attachments || _data)
        }).catch(e => {
          this.formErrors = e?._errors || {}
        }).finally(() => {
          this.loader = !1
        })
      })
    }
  }
}
</script>
