<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <v-tooltip top>
    <template #activator="{ on, attrs }">
      <v-container fluid>
        <v-card
          v-if="attachment"
          :elevation="elevation"
          :height="height"
          :width="width"
          v-bind="{...$attrs,...attrs}"
          v-on="{...$listeners,...on}"
        >
          <v-row
            align="center"
            align-content="center"
            class="fill-height pa-1"
            justify="center"
            no-gutters
          >
            <v-col
              class="text-center"
              cols="auto"
            >
              <v-avatar
                color="white"
                rounded
                size="50"
              >
                <v-img
                  :src="iconSource"
                  :width="width*0.5"
                  class="mx-auto"
                />
              </v-avatar>
              <span class="text font-weight-black">
                {{ attachment.description || attachment.name }}
              </span>
            </v-col>
            <v-col cols="auto">
              <v-btn
                :href="attachment.download_url"
                icon
                target="_blank"
              >
                <v-icon>download</v-icon>
              </v-btn>
              <v-btn
                v-if="showDestroy"
                icon
                @click="deleteFile(attachment.model_id,attachment.id)"
              >
                <v-icon>delete</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
        <v-dialog
          v-model="loading"
          no-click-animation
          persistent
          width="250"
        >
          <v-card
            color="primary"
            dark
          >
            <v-card-text>
              &nbsp;
              <v-progress-linear
                class="mb-0"
                color="white"
                indeterminate
              />
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-container>
    </template>
    <span v-if="attachment.user_name">{{ attachment.user_name }}
      <br>
    </span>
    <span>{{ attachment.description || attachment.name }}</span>
  </v-tooltip>
</template>

<script>

export default {
  name: 'AttachmentBlock',
  props: {
    url: {
      type: Object,
      default: () => undefined
    },
    attachment: {
      type: Object,
      default: () => undefined
    },
    elevation: {
      type: [String, Number],
      default: () => 8
    },
    width: {
      type: [String, Number],
      default: () => 100
    },
    height: {
      type: [String, Number],
      default: () => 158
    },
    showDestroy: {
      required: !1,
      type: Boolean,
      default: () => !0
    }
  },
  data () {
    return {
      loading: !1,
      iconSource: require('@icons/document.png')
    }
  },
  methods: {
    download () {
      if (this.attachment?.download_url) {
        window.location.href = this.attachment.download_url
      }
    },
    deleteFile (model, id) {
      if (this.loading) return

      this.confirmMessage(async () => {
        this.loading = !0
        try {
          const { _data, _message } = await this.url.deleteAttachment(model, id)
          // _message && this.alertSuccess(_message)
          _data && this.$emit('update-item', _data)
        } catch (e) {
          e?._message && this.alertError(e._message)
        } finally {
          this.loading = !1
        }
      })
    }
  }
}
</script>
<style scoped>
.text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  text-align: center;
}
</style>
