<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <app-card :title="cardTitle">
    <v-skeleton-loader
      :loading="loading"
      type="table"
    >
      <div>
        <order-show
          v-if="item"
          :order.sync="item"
        />
      </div>
    </v-skeleton-loader>
    <template #afterTitle>
      <v-spacer />
      <app-btn @click="goBack">
        {{ parseAttribute('back') }}
      </app-btn>
    </template>
  </app-card>
</template>
<script>
import { DatatableMixin, MetaInfoMixin } from '@mixins'
import OrderShow from '@components/order/OrderShow'

export default {
  name: 'OrderPage',
  components: { OrderShow },
  mixins: [MetaInfoMixin, DatatableMixin],
  data () {
    return {
      modelPermission: 'Sales.Order',
      loading: !0,
      id: undefined,
      item: undefined
    }
  },
  computed: {
    cardTitle () {
      return this.parseAttribute('order_details')
    }
  },
  mounted () {
    this.checkPermission('show')
    this.initItem()
  },
  methods: {
    async initItem () {
      const { item, id } = this.$route.params
      // console.log(this.$route.params, this.id, this.item)
      if (!id && !item) {
        return this.$router.replace({ name: this.APP_ROUTES.notFound })
      }
      if (id && !item) {
        try {
          const { _data, _success } = await this.apiService.order.show(id)
          if (_success) {
            this.loading = !1
            this.$nextTick(() => {
              _data && (this.item = _data)
            })
          }
        } catch (e) {
          const { _message } = e || {}
          _message && this.alertError()
        }
        return
      }

      this.item = item
      this.loading = !1
    },
    goBack () {
      if (this.$route.params.item) {
        this.$router.back()
      } else {
        this.$router.push({ name: this.APP_ROUTES.order.index })
      }
    }
  }
}
</script>
