<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <dialog-modal
    v-model="modal"
    max-width="720"
    no-click-animation
    persistent
    scrollable
  >
    <slide-fade-transition>
      <v-card v-if="dialogItem">
        <v-card-title>
          {{ $t('replace.show', { n: parseAttribute(dialogAttribute) }) }}
          <v-spacer />
          <v-btn
            :disabled="loadingData"
            icon
            @click="closeDialog"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-skeleton-loader
            :loading="loadingData"
            type="table"
          >
            <v-container fluid>
              <v-row
                v-for="(e,i) in items"
                :key="i.toString()"
                align="center"
                align-content="center"
                dense
                justify="space-between"
              >
                <v-col cols="auto">
                  {{ items.length - i }}-
                </v-col>
                <v-col>
                  {{ e.new_value }}
                </v-col>
                <v-col cols="auto">
                  {{ e.user_id_to_string }}
                </v-col>
                <v-col cols="auto">
                  {{ e.date }}
                </v-col>
                <v-col
                  v-if="i+1 !== items.length"
                  cols="12"
                >
                  <v-divider />
                </v-col>
              </v-row>
              <v-row v-if="!items.length">
                <v-col cols="12">
                  <h3 class="text-center error--text">
                    {{ $t('none') }}
                  </h3>
                </v-col>
              </v-row>
            </v-container>
          </v-skeleton-loader>
        </v-card-text>
        <v-card-actions />
      </v-card>
    </slide-fade-transition>
  </dialog-modal>
</template>

<script>

import DialogModal from '@components/base/grid/DialogModal'
import SlideFadeTransition from '@components/SlideFadeTransition'

export default {
  name: 'OrderAttributeLogDialog',
  components: { SlideFadeTransition, DialogModal },
  props: {
    value: {
      type: Boolean,
      default: undefined
    },
    loading: {
      type: Boolean,
      default: () => !1
    }
  },
  data () {
    return {
      modal: !1,
      loadingData: !1,
      dialogItem: undefined,
      dialogAttribute: undefined,
      items: []
    }
  },
  watch: {
    modal: {
      deep: !0,
      handler (v) {
        this.$emit('input', v)
        this.$emit(v === !0 ? 'open' : 'close', { item: this.dialogItem, attribute: this.dialogAttribute, items: this.items })
        if (v === !0) {
          this.$nextTick(async () => this.fetchLog())
        }
      }
    },
    loadingData: {
      deep: !0,
      handler (v) {
        this.$emit('update:loading', v)
      }
    }
  },
  methods: {
    openDialog (item, attribute) {
      this.dialogItem = item
      this.dialogAttribute = attribute
      this.$nextTick(() => (this.modal = !0))
    },
    closeDialog () {
      this.modal = !1
      this.$nextTick(() => {
        this.dialogItem = undefined
        this.dialogAttribute = undefined
        this.items = []
      })
    },
    async fetchLog () {
      if (this.loadingData) return
      this.loadingData = !0
      this.items = []
      try {
        const { _data, _message, _success } = await this.apiService.order.attributeLog(this.dialogItem.id, this.dialogAttribute)
        if (_success === !0) {
          _message && this.alertSuccess(_message)
          this.$nextTick(() => (this.items = _data || []))
        }
      } catch ({ _message, _errors }) {
        _message && this.alertError(_message)
      } finally {
        this.loadingData = !1
      }
    }
  }
}
</script>
