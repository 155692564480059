<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <v-container
    class="fill-height"
    fluid
  >
    <v-row>
      <v-col
        v-for="(e,i) in itemHeaders"
        :key="`or-${i}`"
        cols="12"
        sm="6"
      >
        <v-sheet
          elevation="4"
          height="55"
          rounded
        >
          <v-container
            class="fill-height"
            fluid
          >
            <v-row
              align="center"
              align-content="center"
              dense
              justify="space-between"
            >
              <v-col
                cols="12"
                sm="auto"
              >
                <span class="item-text pe-2">{{ e.text }}:</span>
                <span class="item-value">{{ computedOrder[e.value] || $t('none') }}</span>
                <span
                  v-if="e.value === 'order_no' && userHasPermission('Sales.Order.update',!1)"
                  class="mr-2"
                >
                  <v-btn
                    :to="{
                      name: APP_ROUTES.order.index,
                      query:{ id: computedOrder.id },
                      params:{datatableFilters : { id: computedOrder.id }},
                    }"
                    color="primary"
                  >
                    {{ $t('update') }}
                  </v-btn>
                </span>
              </v-col>

              <v-col
                v-if="e.value === 'mobile' && computedOrder.mobile"
                cols="12"
                sm="auto"
              >
                <v-btn
                  icon
                  @click="copyText(`+966${parseInt(computedOrder.mobile)}`)"
                >
                  <v-icon color="green">
                    mdi-content-copy
                  </v-icon>
                </v-btn>
                <v-btn
                  :href="`tel:+966${parseInt(computedOrder.mobile)}`"
                  icon
                  target="_blank"
                >
                  <v-icon color="green">
                    mdi-phone
                  </v-icon>
                </v-btn>
                <v-btn
                  :href="`https://wa.me/+966${parseInt(computedOrder.mobile)}`"
                  icon
                  target="_blank"
                >
                  <v-icon color="green">
                    mdi-whatsapp
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-sheet>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="12">
        <v-divider />
      </v-col>
    </v-row>
    <v-row
      align="center"
      justify="space-between"
    >
      <v-col cols="auto">
        <h2>{{ parseAttribute('order_notes') }}</h2>
      </v-col>
      <v-col cols="auto">
        <app-btn @click="showNotesLog">
          {{ parseAttribute('show') }}
        </app-btn>
      </v-col>
      <v-col
        v-if="computedOrder.order_notes"
        cols="12"
      >
        <p v-html="computedOrder.order_notes" />
      </v-col>
    </v-row>
    <v-row
      v-if="userHasPermission('updateNotes')"
      dense
    >
      <v-col cols="12">
        <v-divider />
      </v-col>
      <app-textarea-input
        v-model="form.order_notes"
        clearable
        cols="12"
        name="order_notes"
        rows="4"
      />
      <v-col cols="12">
        <app-btn
          :disabled="loading||!form.order_notes"
          color="success"
          @click="updateNotes"
        >
          {{ parseAttribute('update') }}
        </app-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-divider />
      </v-col>
      <v-col cols="12">
        <div class="text-h6">
          {{ parseAttribute('owner_information') }}
        </div>
      </v-col>

      <v-col
        v-for="(e,i) in ownerHeaders"
        :key="`or-${i}`"
        cols="12"
        sm="6"
      >
        <v-sheet
          elevation="4"
          height="55"
          rounded
        >
          <v-container
            class="fill-height"
            fluid
          >
            <v-row
              align="center"
              align-content="center"
              dense
              justify="space-between"
            >
              <v-col
                cols="12"
                sm="auto"
              >
                <span class="item-text pe-2">{{ e.text }}:</span>
                <span class="item-value">{{ computedOrder[e.value] || $t('none') }}</span>
              </v-col>
            </v-row>
          </v-container>
        </v-sheet>
      </v-col>
    </v-row>
    <v-row
      v-if="!hideAttachments"
    >
      <v-col cols="12">
        <v-divider />
      </v-col>
      <v-col cols="12">
        <attachments-container
          :attachments.sync="computedOrder.attachments"
          :item="computedOrder"
          :show-destroy="userHasPermission('Attachment.destroy')"
          :show-upload="userHasPermission('Attachment.upload')"
          :urls="apiService.order"
          accept="application/pdf,image/*"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        &nbsp;
      </v-col>
    </v-row>
    <order-attribute-log-dialog
      ref="orderAttributeLogDialog"
    />
  </v-container>
</template>

<script>

import AttachmentsContainer from '@components/attachments/AttachmentsContainer'
import OrderAttributeLogDialog from '@components/order/OrderAttributeLogDialog'
import getHeadersMixin from '@mixins/GetHeadersMixin'

export default {
  name: 'OrderShow',
  components: { OrderAttributeLogDialog, AttachmentsContainer },
  mixins: [getHeadersMixin],
  props: {
    order: {
      type: Object,
      default: () => ({})
    },
    hideAttachments: {
      type: Boolean,
      default: () => !1
    }
  },
  data () {
    return {
      modelPermission: 'Sales.Order',
      loading: !1,
      loadingAttachments: !1,
      form: {
        order_notes: null
      }
    }
  },
  computed: {
    computedOrder: {
      get () {
        return this.order
      },
      set (v) {
        return this.$emit('update:order', v)
      }
    },
    attachments () {
      return this.order.attachments || []
    },
    itemHeaders () {
      return this.parseHeaders([
        'order_no',
        (this.isAdminUser ? 'user_id_to_string' : null),
        'name',
        'mobile',
        'job_position_id_to_string',
        'salary_to_string',
        'bank_id_to_string',
        'age_to_string',
        'service_type_id_to_string',
        'have_obligations_to_string'
      ])
    },
    ownerHeaders () {
      return this.parseHeaders([
        'owner_name',
        'owner_mobile',
        'down_payment_to_string',
        'amount_before_variance_to_string',
        'amount_after_variance_to_string',
        'finance_amount_to_string',
        'owner_check_to_string',
        'fees_amount_to_string',
        'finance_calc_amount_to_string',
        'real_estate_installment_to_string',
        'finance_bank_id_to_string'
      ])
    }
  },
  methods: {
    showNotesLog () {
      this.$refs.orderAttributeLogDialog.openDialog(this.computedOrder, 'order_notes')
    },
    updateNotes () {
      if (this.loading) return
      this.confirmMessage(async () => {
        this.loading = !0
        try {
          const { _data, _message, _success } = await this.apiService.order.updateNotes(this.computedOrder.id, this.form.order_notes)
          _message && this.alertSuccess(_message)
          _data && (this.computedOrder = _data)
          _success && this.$nextTick(() => (this.form = { order_notes: null }))
        } catch (e) {
          const { _message } = e || {}
          _message && this.alertError(_message)
        } finally {
          this.loading = !1
        }
      })
    }
  }
}
</script>
